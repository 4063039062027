import React, { useEffect, useState } from 'react'
import ImageLazy from "../imageLazy/ImageLazy"
import CustomButton from '../customButton/CustomButton'
import imgNotFounf from "../../assets/notfound.jpeg"
import "./cardSmall.scss"
import { getImage } from '../../service'
import { useSelector } from 'react-redux'
function CardSmall({ item, clicked, donate = false }) {
    const { lang } = useSelector(state => state.lang)
    const [imgSrc, setImgSrc] = useState("")
    const [, setLoading] = useState(false)
    useEffect(() => {
        if (item?.images?.length) {
            setLoading(true)
            getImage(item?.images[0]).then(res => {
                setImgSrc(res)
                setLoading(false)
            })
        }
    }, [item])
    const cutText = (text, num = 20) => {
        if (text?.length >= num) {
            return text?.slice(0, num) + ".."
        } else {
            return text
        }
    }
    return (
        <div className='CardSmall'>
            <section>
                <div className="image">
                    {item?.images?.length ? (
                        <ImageLazy
                            alt={"img"}
                            src={imgSrc}
                        />
                    ) : (
                        <ImageLazy
                            alt={"img"}
                            src={imgNotFounf}
                        />
                    )}
                </div>
                <div className="info">
                    <p className="title">{lang?.lang === "ar" ? cutText(item?.nameAr) : cutText(item?.nameEn)}</p>
                    <p className="subtitle">{item?.Price} EGP</p>
                    <p className="desc">{lang?.lang === "ar" ? cutText(item?.descriptionAr, 70) : cutText(item?.descriptionEn, 70)}</p>
                </div>
            </section>
            <footer>
                {donate ? (
                    <CustomButton clicked={clicked} title={lang?.lang === "ar" ? "تبرع" : "Donate"} />
                ) : ""}
            </footer>
        </div>
    )
}

export default CardSmall