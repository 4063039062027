import React from 'react'
import ImageLazy from '../imageLazy/ImageLazy'
import CustomButton from '../customButton/CustomButton';

// import img1 from "../../assets/imgs/1.jpg" 
import "./cardNormal.scss"
import { useSelector } from 'react-redux';
function CardNormal({ item, clicked }) {
    const { lang } = useSelector(state => state.lang)
    const cutText = (text, num = 20) => {
        if (text?.length >= num) {
            return text?.slice(0, num) + ".."
        } else {
            return text
        }
    }
    console.log(item);

    return (
        <div className='CardNormal'>
            <div className="image">
                <ImageLazy
                    getImageApi={true}
                    src={item?.images[0]}
                    alt={"img"}
                />
            </div>
            <div className="details">
                <p className="name">{cutText(lang?.lang === "ar" ? item?.nameAr : item?.nameEn)}</p>
                <p className="price"><span>{item?.Price}</span> EGP</p>
                <p className="desc">{cutText(lang?.lang === "ar" ? item?.descriptionAr : item?.descriptionEn, 60)}</p>
                <CustomButton clicked={clicked} title={lang?.lang === "ar" ? "تبرع" : "Donate"} />
            </div>

        </div>
    )
}

export default CardNormal