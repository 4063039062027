import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { ImageLazy } from "../../components"
import { useSelector } from 'react-redux';
function Hero() {
    const { dataProfile } = useSelector(state => state.detailsProfile)

    return (
        <div className="wrap">
            <div className='Hero'>
                <Swiper
                    className="mySwiper"
                    loop={true}
                    autoplay={{
                        delay:4000,
                        disableOnInteraction: false
                    }}
                    navigation={true}
                    pagination={true}
                    modules={[Pagination, Navigation, Autoplay]}
                >
                    {dataProfile?.images?.length ? dataProfile?.images?.map(e => (
                        <SwiperSlide key={Math?.random()}>
                            <ImageLazy
                                src={e}
                                alt={"img"}
                                getImageApi={true}
                            />
                        </SwiperSlide>
                    )) :
                        (
                            <>
                                <SwiperSlide >
                                    <ImageLazy
                                        src={require("../../assets/imgs/3.jpg")}
                                        alt={"img"}
                                    />
                                </SwiperSlide>
                                <SwiperSlide >
                                    <ImageLazy
                                        src={require("../../assets/imgs/2.jpg")}
                                        alt={"img"}
                                    />
                                </SwiperSlide>
                            </>
                        )
                    }
                </Swiper>
            </div>
        </div>
    )
}

export default Hero