import React, { useEffect, useState } from 'react'
import { MapComponent, WrapperSection } from '../../components'
import "./branches.scss"
import fetchApi from '../../service'
import { useSelector } from 'react-redux'
function Branches() {
    const { lang } = useSelector(state => state.lang)
    const [location, setLocation] = useState({})
    const [branches, setBranches] = useState([])
    const [active, setActive] = useState(0)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getBranches()
    }, [])
    useEffect(() => {
        if (branches?.length) {
            if (branches[0]?.lat && branches[0]?.long) {
                setLocation({
                    lat: branches[0]?.lat,
                    lng: branches[0]?.long
                })
            }
        }
    }, [branches, active])
    const getBranches = () => {
        fetchApi({
            apiName: "branches/website",
            payload: {
                method: "GET"
            }
        }).then(res => {
            if (res?.data?.success) {
                setBranches(res?.data?.data)
            }
            setLoading(false)
        }).catch(err => setLoading(false))
    }
    const showMap = (e, i) => {
        setActive(i)
        setLocation({
            lat: e?.lat,
            lng: e?.long
        })
    }
    return (
        <div className='page'>
            <WrapperSection title={lang?.words?.branchesAddress}>
                {loading ?
                    <div className='loading'>
                        <div className='loader' />
                    </div>
                    : <div className="Branches">
                        <section className='left'>
                            {branches?.map((e, i) => (
                                <div
                                    key={Math?.random() + i}
                                    onClick={() => showMap(e, i)}
                                    className={`item ${active === i ? "active" : ""}`}
                                >{lang?.lang === "ar" ? e?.nameAr : e?.nameEn}</div>
                            ))}
                        </section>
                        <section className='right'>
                            {location?.lat && location?.lng ? (
                                <MapComponent location={location} />
                            ) : ""}
                        </section>
                    </div>
                }
            </WrapperSection>
        </div>
    )
}

export default Branches