import React from 'react'
import { WrapperSection } from '../../components'
import { useSelector } from 'react-redux'
import "./Privacy.scss"
function Privacy() {
    const { lang } = useSelector(state => state.lang)
    const { dataProfile } = useSelector(state => state.detailsProfile)
    return (
        <div style={{
            direction: lang?.lang === "ar" ? "rtl" : "ltr"
        }} className='Privacy'>
            <WrapperSection
                title={lang?.lang === "ar" ? "الشروط والأحكام" : "Terms and Condition"}
            >
                <section>
                    <p className='bold'>
                        {lang?.lang === "en"
                            ?
                            `Privacy Policy and Terms and Conditions for the Nabd Al-Hayat Foundation Website ${dataProfile?.nameEn}`
                            : `سياسة الخصوصية والأحكام والشروط لموقع ${dataProfile?.nameAr}`
                        }
                    </p>
                    <p className='boldx'>
                        {lang?.lang === "en"
                            ?
                            "Privacy Policy"
                            : "سياسة الخصوصية"
                        }
                    </p>
                    <p className='bold'>
                        {
                            lang?.lang === "en"
                                ?
                                "Introduction"
                                : "مقدمة"
                        }
                    </p>
                    <p className='light'>
                        {
                            lang?.lang === "en"
                                ?
                                `
                               Nabd Al-Hayat Foundation is committed to protecting the privacy of visitors to its website and ensuring that the personal information you provide is protected and used responsibly. This Privacy Policy is part of our commitment to transparency and security. 
                                `
                                : "تلتزم مؤسسة نبض الحياة بحماية خصوصية زوار موقعها الإلكتروني وضمان أن تكون المعلومات الشخصية التي تقدمها محمية ومستخدمة بشكل مسؤول. تُعد سياسة الخصوصية هذه جزءًا من التزامنا بالشفافية والأمان."
                        }
                    </p>
                    <p className='bold'>
                        {
                            lang?.lang === "en"
                                ?
                                "Information Collection"
                                :
                                "جمع المعلومات"
                        }
                    </p>
                    <p className='light'>
                        {
                            lang?.lang === "en"
                                ?
                                `We collect personal information that you voluntarily provide when you register on the website, donate, or subscribe to newsletters. This information may include your name, address, phone number, email address, and payment information.`
                                :
                                "نجمع المعلومات الشخصية التي تقدمها طواعية عند التسجيل في الموقع أو التبرع أو الاشتراك في النشرات الإخبارية. هذه المعلومات قد تشمل الاسم، العنوان، رقم الهاتف، البريد الإلكتروني، ومعلومات الدفع."
                        }
                    </p>
                    <p className='bold'>
                        {
                            lang?.lang === "en"
                                ?
                                "Use of Information"
                                :
                                "استخدام المعلومات"
                        }
                    </p>
                    <p className='light'>
                        {
                            lang?.lang === "en"
                                ?
                                "We use the information we collect for the following purposes:"
                                :
                                "نستخدم المعلومات التي نجمعها للأغراض التالية:"
                        }
                    </p>
                    <ul>
                        <li className='light'>
                            {
                                lang?.lang === "en"
                                    ?
                                    "To improve and personalize your experience on the website."
                                    :
                                    "تحسين وتخصيص تجربتك على الموقع."
                            }
                        </li>
                        <li className='light'>
                            {
                                lang?.lang === "en"
                                    ?
                                    "To process donations and other financial transactions."
                                    :
                                    "معالجة التبرعات والإجراءات المالية الأخرى."
                            }
                        </li>
                        <li className='light'>
                            {
                                lang?.lang === "en"
                                    ?
                                    `To send updates and newsletters related to our activities.`
                                    :
                                    "إرسال تحديثات ونشرات إخبارية ذات صلة بأنشطتنا."
                            }
                        </li>
                        <li className='light'>
                            {
                                lang?.lang === "en"
                                    ?
                                    `To improve our services and respond to your inquiries.`
                                    :
                                    "تحسين خدماتنا والرد على استفساراتك."
                            }
                        </li>
                    </ul>
                    <p className='bold'>
                        {
                            lang?.lang === "en"
                                ?
                                "Information Protection"
                                :
                                "حماية المعلومات"
                        }
                    </p>
                    <p className="light">
                        {
                            lang?.lang === "en"
                                ?
                                `We take necessary security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. We use encryption technologies and security protocols to ensure the confidentiality of your data.`
                                :
                                "نحن نتخذ التدابير الأمنية اللازمة لحماية معلوماتك الشخصية من الوصول غير المصرح به أو التغيير أو الإفشاء أو التدمير. نستخدم تقنيات التشفير والبروتوكولات الأمنية لضمان سرية بياناتك."
                        }
                    </p>
                    <p className='bold'>
                        {
                            lang?.lang === "en"
                                ?
                                "Information Sharing"
                                :
                                "مشاركة المعلومات"
                        }
                    </p>
                    <p className="light">
                        {
                            lang?.lang === "en"
                                ?
                                `We do not sell, rent, or trade your personal information with third parties for marketing purposes. We may share information with trusted service providers who assist us in operating the website and managing our activities, with their commitment to protecting your privacy`
                                :
                                "لا نقوم ببيع أو تأجير أو تداول معلوماتك الشخصية مع أطراف ثالثة لأغراض تسويقية. قد نشارك المعلومات مع مزودي الخدمة الموثوق بهم الذين يساعدوننا في تشغيل الموقع وإدارة أنشطتنا، مع التزامهم بحماية خصوصيتك"
                        }
                    </p>
                    <p className='bold'>
                        {
                            lang?.lang === "en"
                                ?
                                "Your Rights"
                                :
                                "حقوقك"
                        }
                    </p>
                    <p className="light">
                        {
                            lang?.lang === "en"
                                ?
                                `You have the right to access, correct, and delete your personal information that we hold. You can contact us via email for assistance with any of these requests.`
                                :
                                "يحق لك الوصول إلى معلوماتك الشخصية التي نحتفظ بها، وتصحيحها، وحذفها عند الضرورة. يمكنك التواصل معنا عبر البريد الإلكتروني للحصول على المساعدة بخصوص أي من هذه الطلبات."
                        }
                    </p>
                    <p className='bold'>
                        {
                            lang?.lang === "en"
                                ?
                                "Changes to the Privacy Policy"
                                :
                                "تعديلات على سياسة الخصوصية"
                        }
                    </p>
                    <p className="light">
                        {
                            lang?.lang === "en"
                                ?
                                `
                                    We reserve the right to amend this Privacy Policy at any time. Any changes will be posted on this page, and we encourage you to review it periodically.
                                    `
                                :
                                "نحتفظ بالحق في تعديل سياسة الخصوصية هذه في أي وقت. سيتم نشر أي تغييرات على هذه الصفحة، ونشجعك على مراجعتها دوريًا."
                        }
                    </p>
                    <hr />
                    <p className='boldx'>
                        {lang?.lang === "en"
                            ?
                            "Terms and Conditions"
                            :
                            "الأحكام والشروط"
                        }
                    </p>
                    <p className='bold'>
                        {lang?.lang === "en"
                            ?
                            "Introduction"
                            :
                            "مقدمة"
                        }
                    </p>
                    <p className="light">
                        {
                            lang?.lang === "en"
                                ?
                                `Welcome to the Nabd Al-Hayat Foundation website. By using this website, you agree to comply with and be bound by the following terms and conditions, which aim to ensure a safe and beneficial experience for everyone.`
                                :
                                "مرحبًا بك في موقع مؤسسة نبض الحياة. باستخدامك لهذا الموقع، فإنك توافق على الامتثال والالتزام بالأحكام والشروط التالية، التي تهدف إلى ضمان تجربة آمنة ومفيدة للجميع."
                        }
                    </p>
                    <p className='bold'>
                        {
                            lang?.lang === "en"
                                ?
                                "Use of the Website"
                                :
                                "استخدام الموقع"
                        }
                    </p>
                    <p className="light">
                        {
                            lang?.lang === "en"
                                ?
                                `You are permitted to use this website only for lawful purposes that comply with all applicable laws and regulations. You are prohibited from using the website in any way that may harm, disrupt, or misuse the website or other users.`
                                :
                                "يُسمح لك باستخدام هذا الموقع فقط لأغراض قانونية تتفق مع جميع القوانين واللوائح المعمول بها. يحظر عليك استخدام الموقع بأي شكل من الأشكال التي قد تضر أو تعطل أو تسيء إلى الموقع أو المستخدمين الآخرين."
                        }
                    </p>
                    <p className='bold'>
                        {
                            lang?.lang === "en"
                                ?
                                "Intellectual Property Rights"
                                :
                                "حقوق الملكية الفكرية"
                        }
                    </p>
                    <p className="light">
                        {
                            lang?.lang === "en"
                                ?
                                `
                                All content on this website, including text, images, logos, and graphics, is owned by Nabd Al-Hayat Foundation or licensed to it. Reproduction, distribution, display, or use of any of these materials without prior written permission is prohibited.
                                `
                                :
                                "جميع المحتويات الموجودة على هذا الموقع، بما في ذلك النصوص والصور والشعارات والرسوم البيانية، هي ملك لمؤسسة مؤسسه نبض أو مرخصة لها. يُحظر إعادة إنتاج أو توزيع أو عرض أو استخدام أي من هذه المواد دون إذن كتابي مسبق."
                        }
                    </p>
                    <p className='bold'>
                        {
                            lang?.lang === "en"
                                ?
                                "Donations"
                                :
                                "التبرعات"
                        }
                    </p>
                    <p className="light">
                        {
                            lang?.lang === "en"
                                ?
                                `All donations made through the website are used to support the Foundation's programs and activities.Nabd Al-Hayat Foundation is committed to the optimal use of these donations in accordance with the stated objectives of the projects.`
                                :
                                "جميع التبرعات التي تتم عبر الموقع تُستخدم لدعم برامج وأنشطة المؤسسة. تلتزم مؤسسة نبض الحياة بالاستخدام الأمثل لهذه التبرعات وفقًا للأهداف المعلنة للمشاريع."
                        }
                    </p>
                   
                   
                    <p className='bold'>
                        {
                            lang?.lang === "en"
                                ?
                                "Liability"
                                :
                                "المسؤولية"
                        }
                    </p>
                    <p className="light">
                        {
                            lang?.lang === "en"
                                ?
                                `While we strive to ensure the accuracy of the information on the website, we do not guarantee its completeness, accuracy, or reliability.Nabd Al-Hayat Foundation is not responsible for any damages that may result from the use of the website or reliance on the information provided.`
                                :
                                "على الرغم من أننا نسعى جاهدين لضمان دقة المعلومات على الموقع، إلا أننا لا نضمن اكتمالها أو دقتها أو موثوقيتها. لا تتحمل مؤسسة نبض الحياة أي مسؤولية عن أي أضرار قد تنجم عن استخدام الموقع أو الاعتماد على المعلومات المقدمة فيه."
                        }
                    </p>
                    <p className='bold'>
                        {
                            lang?.lang === "en"
                                ?
                                "External Links"
                                :
                                "الروابط الخارجية"
                        }
                    </p>
                    <p className="light">
                        {
                            lang?.lang === "en"
                                ?
                                `The website may contain links to external websites that are not under the control of Nabd Al-Hayat Foundation. We are not responsible for the content or privacy policies of these websites, and your use of them is at your own risk.`
                                :
                                "قد يحتوي الموقع على روابط لمواقع خارجية لا تخضع لسيطرة مؤسسة نبض الحياة. نحن غير مسؤولين عن محتوى أو سياسات الخصوصية لهذه المواقع، واستخدامك لها يكون على مسؤوليتك الخاصة."
                        }
                    </p>
                    <p className='bold'>
                        {
                            lang?.lang === "en"
                                ?
                                "Modifications"
                                :
                                "التعديلات"
                        }
                    </p>
                    <p className="light">
                        {
                            lang?.lang === "en"
                                ?
                                `
                           Nabd Al-Hayat Foundation reserves the right to amend these terms and conditions at any time. Changes will be effective immediately upon posting on the website. It is recommended to review this page regularly for the latest updates.
                            `
                                :
                                "تحتفظ مؤسسة نبض الحياة بالحق في تعديل هذه الأحكام والشروط في أي وقت. ستكون التغييرات نافذة فور نشرها على الموقع. يُنصح بمراجعة هذه الصفحة بانتظام للاطلاع على أحدث التحديثات."
                        }
                    </p>
                    <p className='bold'>
                        {
                            lang?.lang === "en"
                                ?
                                "Contact"
                                :
                                "التواصل"
                        }
                    </p>
                    <p className="light">
                        {
                            lang?.lang === "en"
                                ?
                                `
                                For any inquiries or comments regarding the Privacy Policy or Terms and Conditions, you can contact us via email or phone available on our Contact Us page.
                                `
                                :
                                "لأي استفسارات أو ملاحظات بخصوص سياسة الخصوصية أو الأحكام والشروط، يمكنك التواصل معنا عبر البريد الإلكتروني أو الهاتف المتاح على صفحة الاتصال بنا."
                        }
                    </p>
                    <p className="light">
                        {
                            lang?.lang === "en"
                                ?
                                `
                                Thank you for trusting Nabd Al-Hayat Foundation. We look forward to your continued support in achieving our mission to serve and develop the community.
                                `
                                :
                                "نشكر ثقتكم بمؤسسة نبض الحياه ونتطلع إلى دعمكم المستمر لتحقيق رسالتنا في خدمة المجتمع وتنميته."
                        }
                    </p>
                </section>
            </WrapperSection>
        </div>
    )
}

export default Privacy