import React, { useEffect, useState } from 'react'
import { CardNormal, WrapperSection } from '../../components'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import fetchApi from '../../service';
import { useDispatch, useSelector } from 'react-redux';
import { setDataDonate } from '../../store/sliceDonate';
import { useNavigate } from 'react-router';
function Causes() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { lang } = useSelector(state => state.lang)
    const [projects, setProjects] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getProjects()
    }, [])
    const getProjects = () => {
        setLoading(true)
        fetchApi({
            apiName: "project-activities/website",
            payload: {
                method: "GET"
            }
        }).then(res => {
            if (res?.data?.success && res?.data?.data?.length) {
                setProjects(res?.data?.data)
            }
            setLoading(false)
        }).catch(err => setLoading(false))
    }
    const handleGoDonate = async (item) => {
        await dispatch(setDataDonate(item))
        navigate("/donate")
    }
    return (
        <WrapperSection
            title={lang?.words?.projects}
        >
            {loading ?
                <div className="loading">
                    <div className='loader' />
                </div>
                : <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    loop={true}
                    centeredSlides={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false
                    }}
                    navigation={true}
                    pagination={true}
                    modules={[EffectCoverflow, Navigation, Pagination, Autoplay]}
                    className="mySwiperCases"
                >
                    {projects?.map(item => (
                        <SwiperSlide key={Math?.random()}>
                            <CardNormal
                                donate={true}
                                clicked={() => handleGoDonate(item)}
                                key={Math?.random()}
                                item={item}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            }
        </WrapperSection>
    )
}

export default Causes