import React, { useEffect, useState } from 'react'
import fetchApi from '../../service'
import { useNavigate, useParams } from 'react-router'
import { CardSmall, ImageLazy, WrapperSection } from '../../components'
import "./project.scss"
import { useDispatch, useSelector } from 'react-redux'
import { setDataDonate } from '../../store/sliceDonate'
function Project() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const { lang } = useSelector(state => state.lang)
    const [project, setProject] = useState({})
    const [projectsActivity, setProjectsActivity] = useState([])
    const [imageSrc, setImageSrc] = useState("")
    const [total, setTotal] = useState(2)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getProjects()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    useEffect(() => {
        if (id) {
            getProjectsActivity(id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    useEffect(() => {
        if (projectsActivity?.length) {
            if (projectsActivity[0]?.images?.length) {
                setImageSrc(projectsActivity[0]?.images[0])
            } else {
                setImageSrc("")
            }
        } else {
            setImageSrc("")
        }
    }, [projectsActivity])
    const getProjectsActivity = (projectId) => {
        fetchApi({
            apiName: "project-activities/projectId/website",
            payload: {
                method: "GET",
                params: {
                    projectId: projectId,
                }
            }
        }).then(res => {
            if (res?.data?.success) {
                setProjectsActivity(res?.data?.data)
            }
        }).catch(err => false)
    }
    const getProjects = () => {
        fetchApi({
            apiName: "project/website",
            payload: {
                method: "GET"
            }
        }).then(res => {
            if (res?.data?.success && res?.data?.data?.length && id) {
                setProject(res?.data?.data?.find(e => e?._id === id))
            }
            setLoading(false)
        }).catch(err => setLoading(false))
    }
    const handleExpandMore = () => {
        setTotal(prev => prev + 2 > projectsActivity?.length ? projectsActivity?.length : prev += 2)
    }
    const handleGoDonate = async (item) => {
        await dispatch(setDataDonate(item))
        navigate("/donate")
    }
    return (
        <div className='page Project'>
            <WrapperSection title={lang?.lang === "en" ? project?.nameEn : project?.nameAr}>
                {loading ? (
                    <div className='loading'>
                        <div className='loader' />
                    </div>
                ) :
                    <>
                        {imageSrc ? (
                            <div className="image">
                                <ImageLazy
                                    getImageApi={true}
                                    src={imageSrc}
                                />
                            </div>
                        ) : ""}
                        <div className="desc">
                            {lang?.lang === "en" ? project?.descriptionEn : project?.descriptionAr}
                        </div>
                        <div className="grid2">
                            {projectsActivity?.slice(0, total).map(item => (
                                <CardSmall
                                    clicked={() => handleGoDonate(item)}
                                    donate={true}
                                    key={Math?.random()}
                                    item={item}
                                />
                            ))}
                        </div>
                        <div onClick={handleExpandMore} className="barMore">
                            <span className='total'>{projectsActivity?.length} / {total}</span>
                            <button className="button">
                                <div className="button-box">
                                    <span className="button-elem">
                                        <svg viewBox="0 0 46 40" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z" />
                                        </svg>
                                    </span>
                                    <span className="button-elem">
                                        <svg viewBox="0 0 46 40">
                                            <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z" />
                                        </svg>
                                    </span>
                                </div>
                            </button>
                        </div>
                    </>
                }
            </WrapperSection>
        </div>
    )
}

export default Project