import { createSlice } from "@reduxjs/toolkit";
import { langAr, langEn } from "../language";

export const sliceLang = createSlice({
  name: "sliceLang",
  initialState: {
    lang: langEn,
  },
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload === "en" ? langEn : langAr;
    },
  },
});

export const { setLang } = sliceLang.actions;

export default sliceLang.reducer;
