export let langAr = {
  lang: "ar",
  words: {
    arabic: "عربي",
    english: "إنجليزي",
    terms: "الشروط والأحكام",
    contactus: "اتصل بنا",
    searchHere: "ابحث هنا",
    login: "الحساب",
    logToAccount: "تسجيل الدخول",
    calculate: "الحاسبة",
    calculateZakat: "أحسب زكاتك",
    cart: "العربة",
    egp: "جنيه",
    aboutus: "معلومات عنا",
    boardofTrustees: "مجلس الأمناء",
    ourPartners: "شركاؤنا",
    branches: "الفروع",
    scopes: "مجالات التنمية",
    supportingDepartments: "الأقسام الداعمة",
    howToDonate: "كيفية التبرع",
    offices: "المكاتب",
    donatingabroad: "التبرع بالخارج",
    donationEPayment: "الدفع الإلكتروني للتبرع",
    orderDelegate: "مندوب الطلب",
    concreteDonations: "التبرعات الملموسة",
    projects: "المشاريع",
    media: "المركز الإعلامى ",
    news: "الأخبار",
    videos: "الفيديوهات",
    ourtrustedpartners: "شركاؤنا الموثوق بهم",
    branchesAddress: "الفروع والعناوين",
    projectsActivity: "المشاريع النشطه",
    abouttheFoundation: "عن المؤسسة",
    program: "البرامج",
    donation: "التبرعات",
    donationCampaigns: "حملات التبرع",
  },
};
export let langEn = {
  lang: "en",
  words: {
    arabic: "Arabic",
    english: "English",
    terms: "Terms & Condition",
    contactus: "Contact us",
    searchHere: "Search Here",
    login: "Login",
    logToAccount: "Log To Account",
    calculate: "Calculate",
    calculateZakat: "Calculate Zakat",
    cart: "Cart",
    egp: "EGP",
    aboutus: "About us",
    boardofTrustees: "Board of Trustees",
    ourPartners: "Our Partners",
    branches: "Branches",
    scopes: "Scopes",
    supportingDepartments: "Supporting Departments",
    howToDonate: "How To Donate",
    offices: "Offices",
    donatingabroad: "Donating abroad",
    donationEPayment: "Donation E-Payment",
    donationCampaigns: "Donation campaigns",
    orderDelegate: "Order delegate",
    concreteDonations: "Concrete Donations",
    projects: "Projects",
    media: "Media",
    news: "News",
    videos: "Videos",
    ourtrustedpartners: "OUR TRUSTED PARTNERS",
    branchesAddress: "Branches & Address",
    projectsActivity: "Projects Activity",
    abouttheFoundation: "About The Foundation",
    program: "Programs",
    donation: "Donation",
  },
};
