import { createSlice } from "@reduxjs/toolkit";

export const sliceDonate = createSlice({
  name: "sliceDonate",
  initialState: {
    dataDonate: {},
  },
  reducers: {
    setDataDonate: (state, action) => {
      state.dataDonate = action.payload;
    },
  },
});
export const { setDataDonate } = sliceDonate.actions;

export default sliceDonate.reducer;
