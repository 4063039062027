import { createSlice } from "@reduxjs/toolkit";

export const sliceProfile = createSlice({
  name: "sliceProfile",
  initialState: {
    dataProfile: {},
  },
  reducers: {
    setDataProfile: (state, action) => {
      state.dataProfile = action.payload;
    },
  },
});
export const { setDataProfile } = sliceProfile.actions;

export default sliceProfile.reducer;
