import React from 'react'
import "./home.scss"
import Hero from './Hero';
import Causes from './Causes';
import Products from './Products';
import { Ourtrusted, Scopes } from '../../components';
import LinksSocial from '../../components/linksSocial/LinksSocial';
function Home() {
    return (
        <div className='Home'>
            <LinksSocial />
            <Hero />
            {/* <Products /> */}
            <Causes />
            <Scopes />
            <Ourtrusted />
        </div>
    )
}

export default Home