import React from 'react'
import "./WrapperSection.scss"
function WrapperSection({ title, subTitle, children }) {
    return (
        <section className='WrapperSection'>
            <div className="wrap">
                <div className="head">
                    <p className="title">{title}</p>
                    {/* <p className="subTitle">{subTitle}</p> */}
                </div>
                {children}
            </div>
        </section>
    )
}

export default WrapperSection