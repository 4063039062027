import React, { useEffect, useState } from 'react'
import fetchApi from '../../service'
import { ImageLazy, WrapperSection } from '../../components'
import "./news.scss"
import { useSelector } from 'react-redux'
function News() {
    const { lang } = useSelector(state => state?.lang)
    const [news, setNews] = useState([])
    const [total, setTotal] = useState(2)
    const [loading, setLoading] = useState(true)
    const handleExpandMore = () => {
        setTotal(prev => prev + 2 > news?.length ? news?.length : prev += 2)
    }
    useEffect(() => {
        getNews()
    }, [])
    const getNews = () => {
        fetchApi({
            apiName: "events/website",
            payload: {
                method: "GET"
            }
        }).then(res => {
            if (res?.data?.success) {
                setNews(res?.data?.data)
                setLoading(false)
            }
        }).catch(err => setLoading(false))
    }

    return (
        <div className='page News'>
            <WrapperSection
                title={lang?.words?.news}
            >
                {loading ? (
                    <div className='loading'>
                        <div className='loader' />
                    </div>
                ) : (
                    <>
                        <div className="grid1">
                            {news?.slice(0, total).map(item => (
                                <div className="item">
                                    {item?.images?.length ? (
                                        <div className="image">
                                            <ImageLazy getImageApi={true} src={item?.images[0]} />
                                        </div>
                                    ) : (
                                        <div className="image">
                                            <ImageLazy src={require("../../assets/notfound.jpeg")} />
                                        </div>
                                    )}
                                    <span className='date'>{item?.startDate?.slice(0, 10)}</span>
                                    <div className="info">
                                        <h2>{lang?.lang === "en" ? item?.nameEn : item?.nameAr}</h2>
                                        <p>{lang?.lang === "ar" ? item?.descriptionAr : item?.descriptionEn}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {news?.length ? (
                            <div onClick={handleExpandMore} className="barMore">
                                <span className='total'>{news?.length} / {total}</span>
                                <button className="button">
                                    <div className="button-box">
                                        <span className="button-elem">
                                            <svg viewBox="0 0 46 40" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z" />
                                            </svg>
                                        </span>
                                        <span className="button-elem">
                                            <svg viewBox="0 0 46 40">
                                                <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z" />
                                            </svg>
                                        </span>
                                    </div>
                                </button>
                            </div>
                        ) : ""}
                    </>
                )}
            </WrapperSection>
        </div>
    )
}

export default News