import React, { useState } from 'react'
import { CustomInput } from '../../components'
import "./contactus.scss"
import CustomButton from '../../components/customButton/CustomButton'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import fetchApi from '../../service'
function Contactus() {
    const { lang } = useSelector(state => state?.lang)
    const { dataProfile } = useSelector(state => state.detailsProfile)
    console.log(dataProfile);

    const [inpsContactUs, setInpsContactUs] = useState({
        name: "",
        email: "",
        msg: "",
        city: "",
        phone: "",
    })
    const [errors, setErrors] = useState({
        name: false,
        email: false,
        msg: false,
        city: false,
        phone: false,
    })
    const handleSubmit = () => {
        if (inpsContactUs?.name) {
            setErrors(prev => ({ ...prev, name: false }))
        } else {
            setErrors(prev => ({ ...prev, name: true }))
        }
        if (inpsContactUs?.msg) {
            setErrors(prev => ({ ...prev, msg: false }))
        } else {
            setErrors(prev => ({ ...prev, msg: true }))
        }
        if (inpsContactUs?.phone) {
            setErrors(prev => ({ ...prev, phone: false }))
        } else {
            setErrors(prev => ({ ...prev, phone: true }))
        }
        if (
            inpsContactUs?.name &&
            inpsContactUs?.phone
        ) {
            fetchApi({
                apiName: "emails/website",
                payload: {
                    method: "POST",
                    data: {
                        "name": inpsContactUs?.name,
                        "mobileNumber": inpsContactUs?.phone,
                        "email": inpsContactUs?.email,
                        "city": inpsContactUs?.city,
                        "msg": inpsContactUs?.msg,
                        "organization": dataProfile?.staffAdmin?.organization
                    }
                }
            }).then(res => {
                console.log(res?.data);
                if (res?.data?.success) {
                    Swal.fire({
                        title: lang?.lang === "en" ? "Email registered" : "تم تسجيل الطلب ",
                        text: lang?.lang === "en" ? "Your data has been sent successfully." : `تم ارسال بياناتك بنجاح`,
                        icon: "success"
                    });
                    setInpsContactUs({
                        name: "",
                        email: "",
                        msg: "",
                        city: "",
                        phone: "",
                    })
                }
            }).catch(err => {
                Swal.fire({
                    title: "System Error",
                    icon: "error"
                });
            })
        }
    }
    return (
        <div className='ContactUs'>
            <div style={{ direction: lang?.lang === "en" ? "ltr" : "rtl" }} className="wrap">
                <p className="title">{lang?.lang === "en" ? "Contact Us" : "اتصل بنا"}</p>
                <form onSubmit={(e) => {
                    e?.preventDefault()
                    handleSubmit()
                }} className="inps">
                    <div>
                        <span>{lang?.lang === "en" ? "Name" : "الاسم"}</span>
                        <CustomInput
                            error={errors?.name}
                            value={inpsContactUs?.name}
                            onChange={(e) => setInpsContactUs(prev => ({ ...prev, name: e?.target?.value }))}
                            placeholder={lang?.lang === "en" ? "Name" : "الاسم"}
                        />
                    </div>
                    <div>
                        <span>{lang?.lang === "en" ? "Email" : "البريد الالكترونى"}</span>
                        <CustomInput
                            type='email'
                            value={inpsContactUs?.email}
                            onChange={(e) => setInpsContactUs(prev => ({ ...prev, email: e?.target?.value }))}
                            placeholder={lang?.lang === "en" ? "Email" : "البريد الالكترونى"} />
                    </div>
                    <div>
                        <span>{lang?.lang === "en" ? "Phone" : "رقم الهاتف"}</span>
                        <CustomInput
                            type='number'
                            error={errors?.phone}
                            value={inpsContactUs?.phone}
                            onChange={(e) => setInpsContactUs(prev => ({ ...prev, phone: e?.target?.value }))}
                            placeholder={lang?.lang === "en" ? "Phone" : "رقم الهاتف"}
                        />
                    </div>
                    <div>
                        <span>{lang?.lang === "en" ? "Message" : "الرساله"}</span>
                        <CustomInput
                            textarea={true}
                            error={errors?.msg}
                            value={inpsContactUs?.msg}
                            onChange={(e) => setInpsContactUs(prev => ({ ...prev, msg: e?.target?.value }))}
                            placeholder={lang?.lang === "en" ? "Message" : "الرساله"}
                        />
                    </div>
                    <div className="btn">
                        <CustomButton title={lang?.lang === "en" ? "Send" : "أرسل"} />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Contactus