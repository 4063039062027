import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import WrapperSection from '../wrapperSection/WrapperSection';
import ImageLazy from '../imageLazy/ImageLazy';
import fetchApi from '../../service';
import 'swiper/css';
import 'swiper/css/pagination';
import "./Ourtrusted.scss"
import { useSelector } from 'react-redux';

function Ourtrusted() {
    const { lang } = useSelector(state => state.lang)
    const [channeles, setChanneles] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getAllChanle()
    }, [])
    const getAllChanle = () => {
        setLoading(true)
        fetchApi({
            apiName: "channele/website",
            payload: {
                method: "GET"
            }
        }).then(res => {
            if (res?.data?.success) {
                setChanneles(res?.data?.data)
            }
            setLoading(false)
        }).catch(() => setLoading(false))
    }
    return (
        <div className="Ourtrusted">
            <WrapperSection
                title={lang?.words?.ourtrustedpartners}
            >
                {loading ?
                    <div className='loading'>
                        <div className='loader' />
                    </div> :
                    <Swiper
                        slidesPerView={"auto"}
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={{
                            delay: 300,
                            disableOnInteraction: false
                        }}
                        modules={[Pagination, Autoplay]}
                        className="mySwiper"
                    >
                        {channeles?.map((item, i) => {
                            return (
                                <SwiperSlide key={`num${i}`}>
                                    {item?.images?.length ? (
                                        <div className="logo">
                                            <ImageLazy getImageApi={true} alt={"img"} src={item?.images[0]} />
                                        </div>
                                    ) : (
                                        <div className="logo">
                                            <ImageLazy alt={"img"} src={require("../../assets/notfound.jpeg")} />
                                        </div>
                                    )}
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>}
            </WrapperSection>
        </div>
    )
}

export default Ourtrusted