import React from 'react'
import { CardFullPage, WrapperSection } from '../../components'
import "./aboutus.scss"
import { useSelector } from 'react-redux'
function Aboutus() {
    const { lang } = useSelector(state => state.lang)
    const { dataProfile } = useSelector(state => state.detailsProfile)
    return (
        <div className='page Aboutus'>
            <WrapperSection
                title={lang?.words?.aboutus}
            >
                <CardFullPage item={{
                    title: lang?.lang === "ar" ? dataProfile?.nameAr : dataProfile?.nameEn,
                    desc: lang?.lang === "ar" ? dataProfile?.descriptionAr : dataProfile?.descriptionEn,
                    images: dataProfile?.images,
                }} />
            </WrapperSection>
        </div>
    )
}

export default Aboutus