import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CustomInput, ImageLazy } from '../../components'
import "./donate.scss"
import CustomButton from '../../components/customButton/CustomButton'
import fetchApi from '../../service'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router'
function Donate() {
    const navigate = useNavigate()
    const { lang } = useSelector(state => state.lang)
    const { dataDonate } = useSelector(state => state.donate)
    const [project, setProject] = useState({})
    const [inpsDonate, setInpsDonate] = useState({
        name: "",
        mobileNumber: "",
        email: "",
        amount: "",
    })
    const [errors, setErrors] = useState({
        name: false,
        mobileNumber: false,
        email: false,
        amount: false,
    })
    useEffect(() => {
        setProject(dataDonate);
    }, [dataDonate])
    const handleSubmit = () => {
        if (inpsDonate?.name) {
            setErrors(prev => ({ ...prev, name: false }))
        } else {
            setErrors(prev => ({ ...prev, name: true }))
        }
        if (inpsDonate?.email) {
            setErrors(prev => ({ ...prev, email: false }))
        } else {
            setErrors(prev => ({ ...prev, email: true }))
        }
        if (inpsDonate?.mobileNumber) {
            setErrors(prev => ({ ...prev, mobileNumber: false }))
        } else {
            setErrors(prev => ({ ...prev, mobileNumber: true }))
        }
        if (inpsDonate?.amount) {
            setErrors(prev => ({ ...prev, amount: false }))
        } else {
            setErrors(prev => ({ ...prev, amount: true }))
        }
        if (
            inpsDonate?.name &&
            inpsDonate?.mobileNumber &&
            inpsDonate?.email &&
            inpsDonate?.amount
        ) {
            Swal.fire({
                title: lang?.lang === "en" ? "Are you sure?" : "هل أنت متأكد؟",
                text: lang?.lang === "en" ? "Are you sure you want to send this data?" : "هل انت متأكد من ارسال هذه البيانات",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: lang?.lang === "en" ? "Yes Submit" : "نعم ارسال",
                cancelButtonText: lang?.lang === "en" ? "No" : "الغاء"
            }).then((result) => {
                if (result.isConfirmed) {
                    fetchApi({
                        apiName: "requests/website",
                        payload: {
                            method: "POST",
                            data: {
                                "name": inpsDonate?.name,
                                "mobileNumber": inpsDonate?.mobileNumber,
                                "email": inpsDonate?.email,
                                "amount": inpsDonate?.amount,
                                "projectActivity": project?._id,
                                "organization": project?.organization
                            }
                        }
                    }).then(res => {
                        if (res?.data?.success) {
                            Swal.fire({
                                title: lang?.lang === "en" ? "The request has been registered" : "تم تسجيل الطلب",
                                text: lang?.lang === "en" ? "Your data has been sent successfully." : `تم ارسال بياناتك بنجاح`,
                                icon: "success"
                            });
                            setInpsDonate({
                                name: "",
                                mobileNumber: "",
                                email: "",
                                amount: "",
                            })
                        }
                    }).catch(err => {
                        Swal.fire({
                            title: "System Error",
                            icon: "error"
                        });
                    })
                }
            });
        }
    }
    const handleBack = () => {
        Swal.fire({
            title: lang?.lang === "en" ? "Back to main page" : "الرجوع الي الرئيسيه",
            icon: "info"
        }).then(res => {
            if (res?.isConfirmed) {
                navigate("/")
            }
        }).catch(err => false)
    }
    if (!project) return false
    return (
        <div style={{ direction: lang?.lang === "ar" ? "rtl" : "ltr" }} className='Donate'>
            <div className="wrap">
                <div className="detailsProject">
                    {project?.images?.length ? (
                        <div className="img">
                            <ImageLazy getImageApi={true} src={project?.images[0]} />
                        </div>
                    ) : ""}
                    <div className="info">
                        <p className='name'>{lang?.lang === "en" ? project?.nameEn : project?.nameAr} </p>
                        <p className='description'>{lang?.lang === "en" ? project?.descriptionEn : project?.descriptionAr} </p>
                        <p className='price'>{project?.Price} {lang?.lang === "en" ? "EGP" : "ج.م"}</p>
                    </div>
                </div>
                <form onSubmit={(e) => {
                    e?.preventDefault()
                    handleSubmit()
                }} className="inps">
                    <div>
                        <span>{lang?.lang === "en" ? "Name" : "الاسم"}</span>
                        <CustomInput
                            error={errors.name}
                            value={inpsDonate?.name}
                            onChange={(e) => setInpsDonate(prev => ({ ...prev, name: e?.target?.value }))}
                            placeholder={lang?.lang === "en" ? "Name" : "الاسم"}
                        />
                    </div>
                    <div>
                        <span>{lang?.lang === "en" ? "Email" : "البريد الالكترونى"}</span>
                        <CustomInput
                            error={errors.email}
                            value={inpsDonate?.email}
                            onChange={(e) => setInpsDonate(prev => ({ ...prev, email: e?.target?.value }))}
                            type='email'
                            placeholder={lang?.lang === "en" ? "Email" : "البريد الالكترونى"}
                        />
                    </div>
                    <div>
                        <span>{lang?.lang === "en" ? "Phone" : "رقم الهاتف"}</span>
                        <CustomInput
                            error={errors.mobileNumber}
                            value={inpsDonate?.mobileNumber}
                            onChange={(e) => setInpsDonate(prev => ({ ...prev, mobileNumber: e?.target?.value }))}
                            type='number' placeholder={lang?.lang === "en" ? "Phone" : "رقم الهاتف"}
                        />
                    </div>
                    <div>
                        <span>{lang?.lang === "en" ? "Amount" : "المبلغ"}</span>
                        <CustomInput
                            error={errors.amount}
                            value={inpsDonate?.amount}
                            onChange={(e) => setInpsDonate(prev => ({ ...prev, amount: e?.target?.value }))}
                            type='number' placeholder={lang?.lang === "en" ? "Amount" : "المبلغ"}
                        />
                    </div>
                    <div style={{ gridColumn: "span 2" }} className="btns">
                        <CustomButton title={lang?.lang === "en" ? "Submit" : "ارسال"} />
                        <CustomButton type={"button"} clicked={handleBack} title={lang?.lang === "en" ? "Back" : "رجوع"} />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Donate