import React from 'react'
import { CardFullPage, WrapperSection } from '../../components'
import { useSelector } from 'react-redux'
import { Pagination } from 'swiper/modules'
import { SwiperSlide, Swiper } from 'swiper/react'

function SupportingDepartments() {
    const { lang } = useSelector(state => state.lang)
    const { dataProfile } = useSelector(state => state.detailsProfile)
    return (
        <div className='page'>
            <WrapperSection
                title={lang?.lang === "en" ? "Our Goals" : "اهدافنا"}
            >
                <Swiper
                    className="mySwiper"
                    loop={true}
                    autoplay={{
                        delay: 500,
                        disableOnInteraction: false
                    }}
                    pagination={true} modules={[Pagination]}
                >
                    {dataProfile?.goals?.map(e => (
                        <SwiperSlide key={Math?.random()}>
                            <CardFullPage item={{
                                title: lang?.lang === "ar" ? e?.nameAr : e?.nameEn,
                                desc: lang?.lang === "ar" ? e?.descriptionAr : e?.descriptionEn,
                                images: e?.images
                            }} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </WrapperSection>
        </div>
    )
}

export default SupportingDepartments