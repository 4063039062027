import React from 'react'
import { Scopes, WrapperSection } from '../../components'
import { useSelector } from 'react-redux'

function ScopesPage() {
    const { lang } = useSelector(state => state.lang)
    return (
        <div className='page Scopes'>
            <WrapperSection
                title={lang?.words?.scopes}
            >
                <Scopes />
            </WrapperSection>
        </div>
    )
}

export default ScopesPage