import React, { useEffect, useState } from 'react'
import { CardSmall, WrapperSection } from '../../components'
import "./causes.scss"
import fetchApi from '../../service'
import { useDispatch, useSelector } from 'react-redux'
import { setDataDonate } from '../../store/sliceDonate'
import { useNavigate } from 'react-router'
function Causes() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { lang } = useSelector(state => state.lang)
    const [projects, setProjects] = useState([])
    const [active, setActive] = useState(0)
    const [projectsActivity, setProjectsActivity] = useState([])
    const [total, setTotal] = useState(2)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getProjects()
    }, [])
    useEffect(() => {
        if (projects?.length) {
            getProjectsActivity(projects[0]?._id)
        }
    }, [projects])
    const getProjectsActivity = (projectId) => {
        fetchApi({
            apiName: "project-activities/projectId/website",
            payload: {
                method: "GET",
                params: {
                    projectId: projectId,
                }
            }
        }).then(res => {
            if (res?.data?.success) {
                setProjectsActivity(res?.data?.data)
            }
        }).catch(err => false)
    }
    const handleExpandMore = () => {
        setTotal(prev => prev + 2 > projectsActivity?.length ? projectsActivity?.length : prev += 2)
    }
    const getProjects = () => {
        fetchApi({
            apiName: "project/website",
            payload: {
                method: "GET"
            }
        }).then(res => {
            if (res?.data?.success) {
                setProjects(res?.data?.data)
            }
            setLoading(false)
        }).catch(err => setLoading(false))
    }
    const showProjectActicity = (e, i) => {
        setActive(i)
        getProjectsActivity(e?._id)
    }
    const cutText = (text) => {
        if (text?.length >= 20) {
            return text?.slice(0, 20) + ".."
        } else {
            return text
        }
    }
    const handleGoDonate = async (item) => {
        await dispatch(setDataDonate(item))
        navigate("/donate")
    }
    return (
        <div className='page CausesPage'>
            <WrapperSection title={lang?.words?.projects}>
                {loading ?
                    <div className='loading'>
                        <div className='loader' />
                    </div>
                    : <div style={{ direction: lang?.lang === "ar" ? "rtl" : "ltr" }} className="Causes">
                        <section className='left'>
                            {projects?.map((e, i) => (
                                <div
                                    onClick={() => showProjectActicity(e, i)}
                                    key={Math?.random()}
                                    className={`item ${active === i ? "active" : ""}`}
                                >{cutText(lang?.lang === "ar" ? e?.nameAr : e?.nameEn)}</div>
                            ))}
                        </section>
                        <section className='right'>
                            <div className="grid2">
                                {projectsActivity?.slice(0, total).map(item => (
                                    <CardSmall
                                        clicked={() => handleGoDonate(item)}
                                        donate={true}
                                        key={Math?.random()}
                                        item={item}
                                    />
                                ))}
                            </div>
                            {projectsActivity?.length ? (
                                <div onClick={handleExpandMore} className="barMore">
                                    <span className='total'>{projectsActivity?.length} / {total}</span>
                                    <button className="button">
                                        <div className="button-box">
                                            <span className="button-elem">
                                                <svg viewBox="0 0 46 40" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z" />
                                                </svg>
                                            </span>
                                            <span className="button-elem">
                                                <svg viewBox="0 0 46 40">
                                                    <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z" />
                                                </svg>
                                            </span>
                                        </div>
                                    </button>
                                </div>
                            ) : ""}
                        </section>
                    </div>
                }
            </WrapperSection>
        </div>
    )
}

export default Causes