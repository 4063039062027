import React, { useEffect, useState } from 'react'
import ImageLazy from '../imageLazy/ImageLazy'
import img from '../../assets/logo.png'
import { FaTelegramPlane } from "react-icons/fa";
import { Link } from 'react-router-dom';
import "./footer.scss"
import { useSelector } from 'react-redux';
import fetchApi from '../../service';
import Swal from 'sweetalert2';
function Footer() {
    const { lang } = useSelector(state => state.lang)
    const { dataProfile } = useSelector(state => state.detailsProfile)
    const [projects, setProjects] = useState("")
    const [email, setEmail] = useState("")
    useEffect(() => {
        getProjects()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang?.lang])
    const getProjects = () => {
        fetchApi({
            apiName: "project/website",
            payload: {
                method: "GET"
            }
        }).then(res => {
            if (res?.data?.success) {
                if (res?.data?.data?.length) {
                    setProjects([...res?.data?.data?.slice(0, 4)?.map(e => ({ name: lang?.lang === "ar" ? e?.nameAr?.slice(0, 23) : e?.nameEn?.slice(0, 23), path: `/project/${e?._id}` }))])
                }
            }
        }).catch(err => false)
    }
    let arr = [
        {
            title: lang?.words?.aboutus,
            links: [
                {
                    name: lang?.words?.abouttheFoundation,
                    path: "/aboutus"
                },
                {
                    name: lang?.words?.boardofTrustees,
                    path: "/aboutus/boardofTrustees"
                },
                {
                    name: lang?.words?.ourPartners,
                    path: "/aboutus/ourtrustedPartner"
                },
            ]
        },
        {
            title: lang?.words?.program,
            links: projects
        },
        {
            title: lang?.words?.donation,
            links: [
                {
                    name: lang?.words?.donationCampaigns,
                    path: "/causes"
                },

            ]
        },
    ]
    const handleSubmit = () => {
        if (email) {
            fetchApi({
                apiName: "emails/website",
                payload: {
                    method: "POST",
                    data: {
                        "email": email,
                        "organization": dataProfile?.staffAdmin?.organization
                    }
                }
            }).then(res => {
                if (res?.data?.success) {
                    Swal.fire({
                        title: lang?.lang === "en" ? "Email registered" : "تم تسجيل الايميل ",
                        text: lang?.lang === "en" ? "Your data has been sent successfully." : `تم ارسال بياناتك بنجاح`,
                        icon: "success"
                    });
                    setEmail("")
                }
            }).catch(err => {
                Swal.fire({
                    title: "System Error",
                    icon: "error"
                });
            })
        }
    }
    return (
        <footer className='Footer'>
            <div className="wrap">
                <section style={{ direction: lang?.lang === "ar" ? "rtl" : "ltr" }} className='top'>
                    <section className='left'>
                        <div className="logo">
                            {dataProfile?.logo ? (
                                <ImageLazy
                                    alt={"img"}
                                    src={dataProfile?.logo}
                                    getImageApi={true}
                                />
                            ) : (
                                <ImageLazy
                                    alt={"img"}
                                    src={img}
                                    getImageApi={true}
                                />
                            )}
                        </div>
                        <span>{lang?.lang === "ar" ? dataProfile?.nameAr : dataProfile?.nameEn}</span>
                    </section>
                    <section className='right'>
                        <input placeholder={lang?.lang === "en" ? "Enter Email" : "أدخل البريد الإلكتروني"} type="text" value={email} onChange={(e) => setEmail(e?.target?.value)} />
                        <i onClick={handleSubmit}><FaTelegramPlane /></i>
                    </section>
                </section>
                <section className='center'>
                    <ul>
                        {arr?.map((e, i) => (
                            <li key={`num${Math.random() + i}`}>
                                <p>{e.title}</p>
                                {e?.links?.length && e?.links?.map((link) => (
                                    <Link to={link?.path} key={Math?.random()} className='link'>
                                        {link?.name}
                                    </Link>
                                ))}
                            </li>
                        ))}
                    </ul>
                    <p>{lang?.lang === "en" ? dataProfile?.descriptionEn : dataProfile?.descriptionAr}</p>
                </section>
            </div>
            <section className='bottom'>
                <div className="wrap">
                    <p>Copyright © {new Date().getFullYear()} {lang?.lang === "ar" ? dataProfile?.nameAr : dataProfile?.nameEn}</p>

                    {/* <div className="icons">
                        <i className="icon">
                            <FaFacebookF />
                        </i>
                        <i className="icon">
                            <FaFacebookF />
                        </i>
                        <i className="icon">
                            <FaFacebookF />
                        </i>
                        <i className="icon">
                            <FaFacebookF />
                        </i>
                    </div> */}
                </div>
            </section>
        </footer>
    )
}

export default Footer