import React from 'react'
import { Ourtrusted } from '../../components'

function OurtrustedPartners() {
    return (
        <div className='page'>
            <Ourtrusted />
        </div>
    )
}

export default OurtrustedPartners