import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { getImage } from '../../service';

const ImageLazy = ({ src, alt, getImageApi = false }) => {
    const [srcImage, setSrcImage] = useState("")
    useEffect(() => {
        if (getImageApi) {
            if (src) {
                getImage(src).then(res => {
                    setSrcImage(res)
                }).catch(() => {
                    setSrcImage(require("../../assets/notfound.jpeg"))
                })
            } else {
                setSrcImage(require("../../assets/notfound.jpeg"))
            }
        }
    }, [src])

    return (
        <div className="img">
            <div className="bg" >
                <LazyLoadImage
                    src={getImageApi ? srcImage : src}
                    effect="blur"
                    alt={alt}
                    style={{ width: "100%", height: "100%" }}
                />
            </div>
        </div>
    )
}

export default ImageLazy