import React from 'react'
import "./LinksSocial.scss"
import { FaFacebookF, FaYoutube } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { useSelector } from 'react-redux';

function LinksSocial() {
    const { dataProfile } = useSelector(state => state.detailsProfile)

    return (
        <div className='LinksSocial active'>
            <ul className="example-2">
                <li className="icon-content">
                    <a
                        href={dataProfile?.facebookPage}
                        aria-label="Spotify"
                        data-social="spotify"
                    >
                        <div className="filled" />
                        <i>
                            <FaFacebookF />
                        </i>
                    </a>
                    <div className="tooltip">Facebook</div>
                </li>
                <li className="icon-content">
                    <a
                        href={dataProfile?.youtubePage}
                        aria-label="Pinterest"
                        data-social="pinterest"
                    >
                        <div className="filled" />
                        <i>
                            <FaYoutube />
                        </i>
                    </a>
                    <div className="tooltip">Youtube</div>
                </li>
                <li className="icon-content">
                    <a
                        href={dataProfile?.instagramPage}
                        aria-label="Dribbble"
                        data-social="dribbble"
                    >
                        <div className="filled" />
                        <i>
                            <AiFillInstagram />
                        </i>
                    </a>
                    <div className="tooltip">Instagram</div>
                </li>
            </ul>
        </div>
    )
}

export default LinksSocial