import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CustomInput } from '../../components'
import "./calculateZakat.scss"
import CustomButton from '../../components/customButton/CustomButton'
function CalculateZakat() {
    const [inpsCalc, setInpsCalc] = useState({
        money: "",
        shares: "",
        bonds: "",
        profits: "",
        ownedProperty: "",
        gold18: "",
        gold21: "",
    })
    const [zakat, setZakat] = useState(0)
    const [zakatOnMoney, setZakatOnMoney] = useState(0)
    const [zakatOnProperties, setZakatOnProperties] = useState(0)
    const [zakatOnGold, setZakatOnGold] = useState(0)
    const [zakatOnOwnedProperty, setZakatOnOwnedProperty] = useState(0)
    const { lang } = useSelector(state => state.lang)
    useEffect(() => {
        calc()
    }, [inpsCalc])
    const goldPrice18 = 3116;
    const goldPrice21 = 3635;
    const calc = () => {

        const zakatOnMoney = (inpsCalc?.money * 0.25).toFixed(2);
        const zakatOnProperties = ((inpsCalc?.shares + inpsCalc?.bonds + inpsCalc?.profits) * 0.025).toFixed(2);
        const zakatOnGold = ((inpsCalc?.gold18 * goldPrice18 + inpsCalc?.gold21 * goldPrice21) * 0.025).toFixed(2);
        const zakatOnOwnedProperty = (inpsCalc?.ownedProperty * 0.025).toFixed(2); // Zakat on owned property

        const totalZakat = (
            parseFloat(zakatOnMoney) +
            parseFloat(zakatOnProperties) +
            parseFloat(zakatOnGold) +
            parseFloat(zakatOnOwnedProperty)
        ).toFixed(2);

        setZakatOnMoney(zakatOnMoney)
        setZakatOnProperties(zakatOnProperties)
        setZakatOnGold(zakatOnGold)
        setZakatOnOwnedProperty(zakatOnOwnedProperty)
        setZakat(totalZakat)
    }
    return (
        <div className='CalculateZakat'>
            <div className="wrap">
                <div style={{ direction: lang?.lang === "ar" ? "rtl" : "ltr" }}>
                    <div className="title">{lang?.lang === "ar" ? 'احسب زكاتك' : "Calculate your zakat"}</div>
                    <div className="description">
                        {lang?.lang === "ar" ? (
                            "كيف تحسب زكاتك؟ حاسبة الزكاة على موقع مؤسسة نبض الحياه، تمكنك من حساب قيمة الزكاة الخاصة بك بعد كتابة المال أو المبلغ الذي تملكه بعد تحقق نصاب الزكاة، وكما يمكنك أيضاً من حساب قيمة زكاة الذهب من خلال إدخال مقدار الذهب وبالتالي تتعرف على قيمة الزكاة الواجبة عليها. ويمكنك حساب الزكاة للممتلكات الخاصة بك أو الأسهم أو السندات بكتابة قيمة السهم أو السند، وبعد ذلك يظهر لك قيمة الزكاة الخاصة بها. وتقوم مؤسسة نبض الحياة بصرف زكاة المال الخاصة بك في مصارف الزكاة الشرعية. * يرجى التواصل مع جهة أو دار فتوى شرعية حتى تتحقق من شروط وضوابط الزكاة الواجبة."
                        ) :
                            "How to calculate your zakat? The Zakat Calculator on the Nabd Al-Hayat Foundation website enables you to calculate the value of your zakat after writing the money or amount you own after achieving the zakat quorum. It also enables you to calculate the value of gold zakat by entering the amount of gold and thus knowing the value of the zakat due on it. You can calculate the zakat for your property, stocks or bonds by writing the value of the stock or bond, after which the value of its zakat will appear.Nabd Al-Hayat Foundation disburses your zakat money to the legitimate zakat expenditures. * Please contact a legitimate authority or fatwa house to verify the conditions and controls of the zakat due."
                        }
                    </div>

                    <section>
                        <div className="left">
                            <div className="col2">
                                <div className="titleBox">{lang?.lang === "en" ? "Zakat On Money" : "زكاة المال"}</div>
                                <ul>
                                    <li>
                                        <span className='text'>{lang?.lang === "en" ? "How much money do you own?" : "قيمة المال الذى املكة"}</span>
                                        <span className='currency'>{lang?.lang === "en" ? "Currency" : "العملة"}</span>
                                    </li>
                                    <li>
                                        <CustomInput
                                            type="number"
                                            value={inpsCalc?.money}
                                            onChange={(e) => setInpsCalc(prev => ({ ...prev, money: e?.target?.value }))}
                                        />
                                        <span className='egp'>{lang?.lang === "en" ? "EGP" : "جنيه"}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col2">
                                <div className="titleBox">{lang?.lang === "en" ? "Zakat On Properties" : "زكاة الاصول والممتلكات"}</div>
                                <ul>
                                    <li>
                                        <span className='text'>{lang?.lang === "en" ? "What is the value of the shares you own in the market?" : "قيمة الأسهم التي امتلكها فى السوق"}</span>
                                        <span className='currency'>{lang?.lang === "en" ? "Currency" : "العملة"}</span>
                                    </li>
                                    <li>
                                        <CustomInput
                                            type="number"
                                            value={inpsCalc?.shares}
                                            onChange={(e) => setInpsCalc(prev => ({ ...prev, shares: e?.target?.value }))}
                                        />
                                        <span className='egp'>{lang?.lang === "en" ? "EGP" : "جنيه"}</span>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <span className='text'>{lang?.lang === "en" ? "What is the value of the bonds you own in the market?" : "قيمة السندات التي امتلكها فى السوق"}</span>
                                        <span className='currency'>{lang?.lang === "en" ? "Currency" : "العملة"}</span>
                                    </li>
                                    <li>
                                        <CustomInput
                                            type="number"
                                            value={inpsCalc?.bonds}
                                            onChange={(e) => setInpsCalc(prev => ({ ...prev, bonds: e?.target?.value }))}
                                        />
                                        <span className='egp'>{lang?.lang === "en" ? "EGP" : "جنيه"}</span>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <span className='text'>{lang?.lang === "en" ? "What is the value of the profits earned" : "قيمة الارباح التي حصلت عليها"}</span>
                                        <span className='currency'>{lang?.lang === "en" ? "Currency" : "العملة"}</span>
                                    </li>
                                    <li>
                                        <CustomInput
                                            type="number"
                                            value={inpsCalc?.profits}
                                            onChange={(e) => setInpsCalc(prev => ({ ...prev, profits: e?.target?.value }))}
                                        />
                                        <span className='egp'>{lang?.lang === "en" ? "EGP" : "جنيه"}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col3">
                                <div className="titleBox">{lang?.lang === "en" ? "Zakat on Gold" : "زكاة الذهب"}</div>
                                <ul>
                                    <li>
                                        <span className='text'>{lang?.lan === "en" ? "What is the weight of gold you own caliber 18 ?" : "وزن الذهب الذي تملكة من عيار 18"}</span>
                                        <span className='currency'>{lang?.lang === "en" ? "Gold Price Today" : "قيمة الذهب اليوم"}</span>
                                        <span className='unit'>{lang?.lang === "en" ? "Unit" : "وحدة القياس"}</span>
                                    </li>
                                    <li>
                                        <CustomInput
                                            type="number"
                                            value={inpsCalc?.gold18}
                                            onChange={(e) => setInpsCalc(prev => ({ ...prev, gold18: e?.target?.value }))}
                                        />
                                        <span className='egp'>{lang?.lang === "en" ? "EGP" : "جنيه"} {goldPrice18}</span>
                                        <span className='egp'>{lang?.lang === "en" ? "gram" : "الجرام"}</span>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <span className='text'>{lang?.lang === "en" ? "What is the weight of gold you own caliber 21 ?" : "وزن الذهب الذي تملكة من عيار 21"}</span>
                                        <span className='currency'>{lang?.lang === "en" ? "Gold Price Today" : "قيمة الذهب اليوم"}</span>
                                        <span className='unit'>{lang?.lang === "en" ? "Unit" : "وحدة القياس"}</span>
                                    </li>
                                    <li>
                                        <CustomInput
                                            type="number"
                                            value={inpsCalc?.gold21}
                                            onChange={(e) => setInpsCalc(prev => ({ ...prev, gold21: e?.target?.value }))}
                                        />
                                        <span className='egp'>{lang?.lang === "en" ? "EGP" : "جنيه"} {goldPrice21}</span>
                                        <span className='egp'>{lang?.lang === "en" ? "gram" : "الجرام"}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col2">
                                <div className="titleBox">{lang?.lang === 'en' ? "Zakat on Owned Property" : "زكاة العقارات المملوكة"}</div>
                                <ul>
                                    <li>
                                        <span className='text'>{lang?.lang === "en" ? "What is the monthly rental value you own?" : "قيمة إيجار العقار الشهرى الذى امتلكة"}</span>
                                        <span className='currency'>{lang?.lang === "en" ? "Currency" : "العملة"}</span>
                                    </li>
                                    <li>
                                        <CustomInput
                                            type="number"
                                            value={inpsCalc?.ownedProperty}
                                            onChange={(e) => setInpsCalc(prev => ({ ...prev, ownedProperty: e?.target?.value }))}
                                        />
                                        <span className='egp'>{lang?.lang === "en" ? "EGP" : "جنيه"}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="right">
                            <div className="title">{lang?.lang === "en" ? "Zakat Value" : "قيمة الزكاة"}</div>
                            <ul>
                                <li>
                                    <span>{lang?.lang === "en" ? "Zakat On Money" : "زكاة المال"}</span>
                                    <span>{zakatOnMoney} {" "}{lang?.lang === "en" ? "EGP" : "ج.م"}</span>
                                </li>
                                <li>
                                    <span>{lang?.lang === "en" ? "Zakat On Properties" : "زكاة الاصول والممتلكات"}</span>
                                    <span>{zakatOnProperties} {" "}{lang?.lang === "en" ? "EGP" : "ج.م"}</span>
                                </li>
                                <li>
                                    <span>{lang?.lang === "en" ? "Zakat on Gold" : "زكاة الذهب"}</span>
                                    <span>{zakatOnGold} {" "}{lang?.lang === "en" ? "EGP" : "ج.م"}</span>
                                </li>
                                <li>
                                    <span>{lang?.lang === "en" ? "Zakat on Owned Property" : "زكاة العقارات المملوكة"}</span>
                                    <span>{zakatOnOwnedProperty} {" "}{lang?.lang === "en" ? "EGP" : "ج.م"}</span>
                                </li>
                            </ul>
                            <div className="total">
                                <div className='item'>
                                    <span>{lang?.lang === "en" ? "Total Value" : "إجمالي مبلغ الزكاة:"}</span>
                                    <span>{zakat}{" "}  <span>{lang?.lang === "en" ? "EGP" : "ج.م"}</span></span>
                                </div>
                                <CustomButton title={lang?.lang === "en" ? "Donate Now" : "تبرع الآن"} />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default CalculateZakat