import React from 'react'
import ImageLazy from '../imageLazy/ImageLazy'
import "./cardFullPage.scss"
function CardFullPage({ item }) {
    return (
        <div className='CardFullPage'>
            <div>
                <div className="headerImage">
                    <ImageLazy
                        getImageApi={true}
                        src={item?.images?.length && item?.images[0]}
                    />
                </div>
                <h2>{item?.title}</h2>
                <span className='desc'>
                    {item?.desc}
                </span>
            </div>
        </div>
    )
}

export default CardFullPage