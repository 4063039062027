import React, { useRef } from "react";
import { Outlet, RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import "./index.scss";
import {
  Aboutus,
  BoardofTrustees,
  Branches,
  CalculateZakat,
  Causes,
  Contactus,
  Donate,
  Home,
  News,
  OurtrustedPartners,
  Privacy,
  Project,
  ScopesPage,
  SupportingDepartments,
  Videos,
} from "./pages";
import { Footer, Header } from "./components";
import { FaArrowUp } from "react-icons/fa6";

const App = () => {
  const handleGoUpPage = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const Layout = () => {

    return (
      <main className="Main">
        <i onClick={handleGoUpPage} className="goUp">
          <FaArrowUp />
        </i>
        <div className="content">
          <Header />
          <Outlet />
          <Footer />
        </div>
      </main>
    );
  };
  const rote = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/aboutus",
          element: <Aboutus />,
        },
        {
          path: "/aboutus/boardofTrustees",
          element: <BoardofTrustees />,
        },
        {
          path: "/aboutus/ourtrustedPartner",
          element: <OurtrustedPartners />,
        },
        {
          path: "/scopes",
          element: <ScopesPage />,
        },
        {
          path: "/scopes/supportingDepartments",
          element: <SupportingDepartments />,
        },
        {
          path: "/causes",
          element: <Causes />,
        },
        {
          path: "/branches",
          element: <Branches />,
        },
        {
          path: "/news",
          element: <News />,
        },
        {
          path: "/videos",
          element: <Videos />,
        },
        {
          path: "/project/:id",
          element: <Project />,
        },
        {
          path: "/calculateZakat",
          element: <CalculateZakat />,
        },
        {
          path: "/contactus",
          element: <Contactus />,
        },
        {
          path: "/donate",
          element: <Donate />,
        },
        {
          path: "/privacy",
          element: <Privacy />,
        },
        {
          path: "*",
          element: <Home />,
        },
      ],
    },
  ]);
  return (
    <div className="app">
      <RouterProvider router={rote} />
    </div>
  );
};
export default App;
