import React, { useEffect, useState } from 'react'
import "./customInput.scss"
import { useSelector } from 'react-redux'
function CustomInput({ value, onChange, textarea = false, error, placeholder, type = "text" }) {
    const { lang } = useSelector(state => state.lang)
    const [errorInp, setErrorInp] = useState(false)
    useEffect(() => {
        setErrorInp(error)
    }, [error])
    return (
        <div className='CustomInput'>
            <div className="form-control">
                {textarea ? (
                    <textarea
                        className={`${error & errorInp ? "error" : ""} textarea input input-alt`}
                        placeholder={placeholder ? placeholder : lang?.lang === "en" ? "Value Here" : "القيمة هنا"}
                        value={value}
                        onChange={(e) => {
                            onChange(e)
                            setErrorInp(!e?.target?.value ? true : false)
                        }}
                        type={type} />
                ) : (
                    <input
                        className={`${error & errorInp ? "error" : ""} input input-alt`}
                        placeholder={placeholder ? placeholder : lang?.lang === "en" ? "Value Here" : "القيمة هنا"}
                        value={value}
                        onChange={(e) => {
                            onChange(e)
                            setErrorInp(!e?.target?.value ? true : false)
                        }}
                        type={type}
                    />
                )}
                <span className="input-border input-border-alt" />
            </div>
        </div>
    )
}

export default CustomInput