import React, { useEffect, useState } from 'react'
import { ImageLazy, WrapperSection } from '../../components'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import fetchApi, { getImage } from '../../service';
import { useSelector } from 'react-redux';
function BoardofTrustees() {
    const { lang } = useSelector(state => state.lang)
    const [member, setMember] = useState([])
    const [image, setImage] = useState("")
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getMember()
    }, [])
    const getMember = () => {
        setLoading(true)
        fetchApi({
            apiName: "founders/website?role=Member",
            payload: {
                method: "GET"
            }
        }).then(res => {
            if (res?.data?.success) {
                setMember(res?.data?.data)
                console.log(res?.data?.data);

            }
            setLoading(false)
        }).catch(err => setLoading(false))
    }
    const cutText = (text, num = 20) => {
        if (text?.length >= num) {
            return text?.slice(0, num) + ".."
        } else {
            return text
        }
    }
    return (
        <div className='page BoardofTrustees'>
            <WrapperSection
                title={lang?.words?.boardofTrustees}
            >
                {loading ?
                    <div className='loading'>
                        <div className='loader' />
                    </div>
                    : <Swiper
                        navigation={true}
                        modules={[Navigation, Pagination]}
                        className="mySwiper"
                        pagination={{
                            dynamicBullets: true,
                        }}
                    >
                        {member?.map(e => {
                            return (
                                <SwiperSlide key={Math?.random()}>
                                    <div className="wrapInfoPerson">
                                        {e?.avatar ? (
                                            <div className="image">
                                                <ImageLazy getImageApi={true} src={e?.avatar} />
                                            </div>
                                        ) : ""}
                                        <div>
                                            <h2>{lang?.lang === "ar" ? e?.nameAr : e?.nameEn}</h2>
                                            <p>{lang?.lang === "ar" ? cutText(e?.bioAr, 200) : cutText(e?.bioEn, 200)}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                }
            </WrapperSection>
        </div>
    )
}

export default BoardofTrustees