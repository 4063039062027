import React, { useEffect, useState } from 'react'
import { WrapperSection } from '../../components'
import "./videos.scss"
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { useSelector } from 'react-redux';
function Videos() {
    const { lang } = useSelector(state => state.lang)
    const { dataProfile } = useSelector(state => state.detailsProfile)
    const [news, setNews] = useState([])
    const [total, setTotal] = useState(2)
    useEffect(() => {
        setNews(dataProfile?.videos)
    }, [dataProfile])
    const handleExpandMore = () => {
        setTotal(prev => prev + 2 > news?.length ? news?.length : prev += 2)
    }

    return (
        <div className='page Videos'>
            <WrapperSection
                title={lang?.words?.videos}
            >
                <div className="grid2">
                    {news?.map(e => (
                        <div key={Math?.random()} className="item">
                            <LiteYouTubeEmbed
                                id={e}
                                title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                            />
                        </div>
                    ))}
                </div>
                {news?.length ? (
                    <div onClick={handleExpandMore} className="barMore">
                        <span className='total'>{news?.length} / {total}</span>
                        <button className="button">
                            <div className="button-box">
                                <span className="button-elem">
                                    <svg viewBox="0 0 46 40" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z" />
                                    </svg>
                                </span>
                                <span className="button-elem">
                                    <svg viewBox="0 0 46 40">
                                        <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z" />
                                    </svg>
                                </span>
                            </div>
                        </button>
                    </div>
                ) : ""}
            </WrapperSection>
        </div>
    )
}

export default Videos