import axios from "axios";
const BASE_URL = " https://nabdfoundation.org/api/";

const fetchApi = ({ apiName, payload }) => {
  return axios({
    url: BASE_URL + apiName,
    ...payload,
    headers: {
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2ZGNiZDE3YmM5NDJmZmJlOWNkYTk1NSIsImlhdCI6MTcyNjYyMDIyMywiZXhwIjoxNzU4MTc3ODIzfQ.Frfj7Fjurs6HopC0W306J6yanOtptAjQgRWcfy9GWRE`,
      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2ZWVmZTBjMzIyYWY5MDVmMjY3MjBkZSIsImlhdCI6MTcyNzA1NTA2OCwiZXhwIjoxNzU4NjEyNjY4fQ.vvHe-J4GE11k01XY9qIrRlXIBX1eoloPN6pzMTzFRFE`,
    },
  });
};
export default fetchApi;

export const getImage = (imgName) => {
  return fetch(`${BASE_URL}organizations/orgfiles?filename=${imgName}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage?.auth || "{}")?.data?.token
        }`,
    },
  })
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      const imageUrl = URL.createObjectURL(blob);
      return imageUrl;
    })
    .catch((error) => {
      console.error("Error fetching the image:", error);
    });
};
