import React, { useEffect, useState } from 'react'
import ImageLazy from '../imageLazy/ImageLazy';
import srcAr from "../../assets/ar.png"
import srcEn from "../../assets/en.png"
import ToggleCheckBox from '../toggleCheckBox/ToggleCheckBox';
import { FaPhoneAlt } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaCalculator } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { IoMenu } from "react-icons/io5";
import { Link, useLocation } from 'react-router-dom';
import { setDataProfile } from '../../store/sliceProfile';
import { useDispatch, useSelector } from 'react-redux';
import { setLang } from '../../store/sliceLang';
import fetchApi, { getImage } from '../../service';
import "./header.scss"
function Header() {
    const dispatch = useDispatch()
    const { lang } = useSelector(state => state.lang)
    const { pathname } = useLocation()
    const [overlay, setOverlay] = useState(false)
    const { dataProfile } = useSelector(state => state.detailsProfile)
    const [logo, setLogo] = useState("")
    useEffect(() => {
        getDetailsProfile()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        getImage(dataProfile?.logo).then(res => {
            setLogo(res)
        }).catch(err => {
            setLogo("")
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataProfile])
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [pathname]);
    useEffect(() => {
        dispatch(setLang(localStorage?.lang || "ar"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getDetailsProfile = () => {
        fetchApi({
            apiName: "organizations/website",
            payload: {
                method: "GET",
            }
        }).then(res => {
            if (res?.data?.success) {
                dispatch(setDataProfile(res?.data?.data))
            }
        }).catch(err => false)
    }
    const handleActiveLink = (i) => {
        let links = document.querySelectorAll('.Header .links .link');
        if (links?.length) {
            if (links[i]?.classList.contains("active")) {
                links?.forEach(e => e.className = "link")
            } else {
                links?.forEach(e => e.className = "link")
                links[i]?.classList.add("active")
            }
        }
    }
    const handleActiveIconsSmallScreen = () => {
        let links = document.querySelector('.Header .links');
        let icons = document.querySelector('.Header .icons');

        links?.classList.remove("active")
        if (icons?.classList.contains("active")) {
            icons?.classList.remove("active")
            setOverlay(false)
        } else {
            icons?.classList.add("active")
            setOverlay(true)
        }
    }
    const handleActiveLinksSmallScreen = () => {
        let links = document.querySelector('.Header .links');
        let icons = document.querySelector('.Header .icons');
        icons?.classList.remove("active")
        if (links?.classList.contains("active")) {
            links?.classList.remove("active")
            setOverlay(false)
        } else {
            links?.classList.add("active")
            setOverlay(true)
        }
    }
    const handleRemoveAllClass = () => {
        setOverlay(false)
        let allLink = document.querySelectorAll('.Header .links .link');
        let links = document.querySelector('.Header .links');
        let icons = document.querySelector('.Header .icons');
        links?.classList.remove("active")
        icons?.classList.remove("active")
        allLink?.forEach(e => e.className = "link")
    }
    const handleChangeLang = (type) => {
        if (type === "ar") {
            dispatch(setLang("ar"));
            localStorage.setItem("lang", "ar");
        } else {
            dispatch(setLang("en"));
            localStorage.setItem("lang", "en");
        }
    };
    let arr = [
        {
            title: lang?.words?.aboutus,
            subTitles: [
                {
                    name: lang?.words?.aboutus,
                    path: "/aboutus"
                },
                {
                    name: lang?.words?.boardofTrustees,
                    path: "/aboutus/boardofTrustees"
                },
                {
                    name: lang?.words?.ourPartners,
                    path: "/aboutus/ourtrustedPartner"
                }
            ]
        },
        {
            title: lang?.words?.scopes,
            subTitles: [
                {
                    name: lang?.words?.scopes,
                    path: '/scopes'
                },
                {
                    name: lang?.words?.supportingDepartments,
                    path: '/scopes/supportingDepartments'
                }
            ]
        },
        {
            title: lang?.words?.howToDonate,
            subTitles: [
                {
                    name: lang?.words?.branches,
                    path: "/branches"
                },
                {
                    name: lang?.words?.offices
                },
                {
                    name: lang?.words?.donatingabroad
                },
                {
                    name: lang?.words?.donationEPayment
                },
                {
                    name: lang?.words?.orderDelegate
                },
                {
                    name: lang?.words?.concreteDonations
                },
            ]
        },
        {
            title: lang?.words?.projects,
            path: "/causes"
        },
        {
            title: lang?.words?.media,
            subTitles: [
                {
                    name: lang?.words?.news,
                    path: "/news"
                },
                {
                    name: lang?.words?.videos,
                    path: "/videos"
                },
            ]
        },
    ]
    return (
        <>
            <div onClick={handleRemoveAllClass} className={`overlay ${overlay ? "active" : ""}`}></div>
            <header style={{ direction: lang?.lang === "ar" ? "rtl" : "ltr" }} className='Header'>
                <section className="topBar">
                    <section className="wrap">
                        <div className='phone'>
                            <i>
                                <FaPhoneAlt />
                            </i>
                            <span>{dataProfile?.hotLine}</span>
                        </div>
                        <div className="boxSearch">
                            <input type="text" placeholder={lang?.words?.searchHere} />
                            <i>
                                <FaSearch />
                            </i>
                        </div>
                        <ul>
                            <li className="imgs">
                                <div onClick={() => handleChangeLang("ar")} className='logoLang'>
                                    <div>
                                        <ImageLazy
                                            src={srcAr}
                                            alt={"ar"}
                                        />
                                    </div>
                                    <span>{lang?.words?.arabic}</span>
                                </div>
                                <div onClick={() => handleChangeLang("en")} className='logoLang'>
                                    <div>
                                        <ImageLazy
                                            src={srcEn}
                                            alt={"en"}
                                        />
                                    </div>
                                    <span>{lang?.words?.english}</span>
                                </div>
                            </li>
                            <li>|</li>
                            <Link to={"/privacy"}>{lang?.words?.terms}</Link>
                            <li>|</li>
                            <Link to={"/contactus"} >{lang?.words?.contactus}</Link>
                        </ul>
                    </section>
                </section>
                <section className='bottomBar'>
                    <div className="wrap">
                        <Link to={"/"} className="logo">
                            {logo ? (
                                <ImageLazy
                                    src={logo}
                                    alt={"ar"}
                                />
                            ) : (
                                <ImageLazy
                                    src={require("../../assets/logo.png")}
                                    alt={"ar"}
                                />
                            )}
                        </Link>
                        <ul className='links'>
                            {arr?.map((item, i) => (
                                <li
                                    className='link'
                                    onClick={() => handleActiveLink(i)}
                                    key={Math?.random()}
                                >
                                    {item?.path ? (
                                        <span>
                                            <Link to={item?.path}>{item?.title}</Link>
                                        </span>
                                    ) : (
                                        <span>{item?.title}</span>
                                    )}
                                    <ul>
                                        {item?.subTitles?.map(e => (
                                            <li onClick={handleRemoveAllClass} key={Math?.random()}>
                                                <Link to={e?.path}>{e?.name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20
                        }}>
                            <div className={`icons`} >
                                <Link to={"/calculateZakat"} className="iconBox">
                                    <i className='icon'>
                                        <FaCalculator />
                                    </i>
                                    <div className="text">
                                        <span>{lang?.words?.calculate}</span>
                                        <p>{lang?.words?.calculateZakat}</p>
                                    </div>
                                </Link>
                                <div className="iconBox">
                                    <i className='icon'>
                                        <FaShoppingCart />
                                    </i>
                                    <div className="text">
                                        <span>{lang?.words?.cart}</span>
                                        <p>0 {lang?.words?.egp}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='btnsScreenSmall'>
                                <i className='btnMenuMobile'
                                    onClick={handleActiveLinksSmallScreen}
                                ><IoMenu /></i>
                                <i className='btnMenuMobile'
                                    onClick={handleActiveIconsSmallScreen}
                                ><FaUser /></i>
                                <ToggleCheckBox />
                            </div>
                        </div>
                    </div>
                </section>
            </header>
        </>

    )
}

export default Header