import React, { useEffect, useState } from 'react'
import { ImageLazy } from "../../components"
import { useSelector } from 'react-redux';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import fetchApi from '../../service';
import "swiper/css";
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "./scopes.scss"
function Scopes() {
    const { lang } = useSelector(state => state.lang)
    const [projects, setProjects] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getProjects()
    }, [])
    const getProjects = () => {
        fetchApi({
            apiName: "project/website",
            payload: {
                method: "GET"
            }
        }).then(res => {
            if (res?.data?.success) {
                setProjects(res?.data?.data)
            }
            setLoading(false)
        }).catch(err => setLoading(false))
    }
    const cutText = (text, num = 200) => {
        if (text?.length >= num) {
            return text?.slice(0, num) + ".."
        } else {
            return text
        }
    }
    return (
        <div className='ScopesCarousel'>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
            >
                {loading ? <div className="loading"><div className='loader' /></div>
                    : projects?.map((item, i) => (
                        <SwiperSlide className='item' key={Math?.random() + i}>
                            <div className="bg">
                                <ImageLazy
                                    getImageApi={true}
                                    src={item?.images?.length ? item?.images[0] : ""}
                                    alt={"img"}
                                />
                            </div>
                            <div className="wrap">
                                <div className="details">
                                    <h2 className='title'>{lang?.lang === "ar" ? item?.nameAr : item?.nameEn}</h2>
                                    <span className='desc'>{lang?.lang === "ar" ? cutText(item?.descriptionAr) : cutText(item?.descriptionEn)}</span>
                                </div>
                                <div className="images">
                                    <div className="imgBack">
                                        {item?.images?.length ? (
                                            item?.images[1] ? (
                                                <div className="img">
                                                    <ImageLazy
                                                        getImageApi={true}
                                                        src={item?.images?.length ? item?.images[1] : ""}
                                                        alt={"img"}
                                                    />
                                                </div>
                                            ) : ""
                                        ) : ""}
                                    </div>
                                    <div className="imgFront">
                                        <ImageLazy
                                            getImageApi={true}
                                            src={item?.images?.length ? item?.images[0] : ""}
                                            alt={"img"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
            </Swiper>
        </div>
    )
}

export default Scopes













