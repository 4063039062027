import { configureStore } from "@reduxjs/toolkit";
import sliceProfile from "./sliceProfile";
import sliceLang from "./sliceLang";
import sliceDonate from "./sliceDonate";
const store = configureStore({
  reducer: {
    detailsProfile: sliceProfile,
    lang: sliceLang,
    donate: sliceDonate,
  },
});

export default store;
